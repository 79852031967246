import React from 'react';

function Help(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel large pt-20">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway page-header font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-5">Help</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Help;