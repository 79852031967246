import React, {useState, useEffect, Fragment} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import Modal from '../../layouts/frontend/Modal';

import Alert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";

import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery'; // Import jQuery

import payments_icon from '../../assets/frontend/images/payments_icon.png';
import arrow_right_90 from '../../assets/frontend/images/arrow_right_90.png';

const per_page = 10;
	
function Payments(){
	
	const navHistory = useNavigate();
  
    // Initial states
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [openAlertDeleted, setOpenAlertDeleted] = useState(false);
	const [paymentList, setPaymentList] = useState([
        //{ id: '', title: "", amount: "", gateway: "", trx_id: "", trx_intent: "", trx_status: "", trx_payer_eamil: "", trx_payer_name: "", random_id: "", created: "", updated: "" },
	]);
	const [paymentsListMessage, setPaymentsListMessage] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [nextPage, setNextPage] = useState(null);
	const [prevPage, setPrevPage] = useState(null);
		
	// Remove the Foundation modal directly
		    
	// Initial call for user list items
	useEffect(() => {

		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Member Payments - useEffect] mounted');
			
			var page = 1;
			getPayments(page);

		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted]);
	
	function getPayments(page){

		setIsLoading(true);
		
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.get('/api/read_payments', {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				},
			    params: {
			        per_page: per_page, // Set your preferred number of items per page
			        page: page, // Specify the page number you want to fetch
			    }
			}).then(response =>{
				if(response.data.status === 200){//HTTP_OK
					
					//set data
					if(response.data.payments && response.data.payments.length > 0){
						setPaymentList(response.data.payments);
						setCurrentPage(response.data.pagination_data.current_page);
						setLastPage(response.data.pagination_data.last_page);
						setNextPage(response.data.pagination_data.next_page);
						setPrevPage(response.data.pagination_data.prev_page);
						setPaymentsListMessage('');
					}else{
						setPaymentList([]);
						setCurrentPage(null);
						setLastPage(null);
						setNextPage(null);
						setPrevPage(null);
						setPaymentsListMessage('No Payments Exist');
					}
						
	            }else if(response.data.status === 401){//HTTP_UNAUTHORIZED
	            
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
	            
					swal("Warning",response.data.message,"warning");
	                	
					navHistory('/login');
					
	            }else if(response.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
					
					//set data
					if(response.data.payments && response.data.payments.length > 0){
						setPaymentList(response.data.payments);
						setCurrentPage(response.data.pagination_data.current_page);
						setLastPage(response.data.pagination_data.last_page);
						setNextPage(response.data.pagination_data.next_page);
						setPrevPage(response.data.pagination_data.prev_page);
						setPaymentsListMessage('');
					}else{
						setPaymentList([]);
						setCurrentPage(null);
						setLastPage(null);
						setNextPage(null);
						setPrevPage(null);
						setPaymentsListMessage('No Payments Exist');
					}
	            }else{//more errors
	            }
		
				setIsLoading(false);
				
			}).catch(function (error) {
				console.log('getPayments error: ',error + ' back-end api call error');
				
				setIsLoading(false);
			});

		}).catch(function (error) {
			console.log('getPayments error: ',error + ' csrf-cookie is outdated');
		
			setIsLoading(false);
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
			
			navHistory('/login');
		});
	};
	const handlePaymentEditClick = (payment) => {
    	alert('Payment EditingComing Soon.');
	};

	const handlePaymentAddClick = () => {
    	alert('Add Payments Coming Soon.');
	};
	
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        
        setOpenAlertDeleted(false);
    };
    
    // Delete row of id:i
    const handleRemoveClick = (i) => {
        const list = [...paymentList];

	    // If the user confirms, proceed with the deletion
		if(list[i]['random_id'] && list[i]['random_id'] !== ''){

			var payment_title = list[i]['title'];
	    	// Show a confirmation dialog
	    	const isConfirmed = window.confirm('Are you sure you want to delete the payment '+payment_title+'?');
			if(isConfirmed){
				deletePaymentFromDB(list[i]['random_id']);//send a specific unique ID to delete
			}
		}
    };

	function deletePaymentFromDB(payment_random_id){
		
		setIsLoading(true);
		
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.delete('/api/delete_payment/'+payment_random_id, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				}
			}).then(response =>{
				if(response.data.status === 200){//HTTP_OK
				
					//TODO: update all state properties
						
					//set data
					if(response.data.payments && response.data.payments.length > 0){
						setPaymentList(response.data.payments);
						setPaymentsListMessage('');
					}else{
						setPaymentList([]);
						setPaymentsListMessage('No Payments Exist');
					}
						
	            }else if(response.data.status === 401){//HTTP_UNAUTHORIZED
					
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
				
					swal("Warning",response.data.message,"warning");
					navHistory('/login');
					
	            }else if(response.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
						
					//set data
					if(response.data.payments && response.data.payments.length > 0){
						setPaymentList(response.data.payments);
						setPaymentsListMessage('');
					}else{
						setPaymentList([]);
						setPaymentsListMessage('No Payments Exist');
					}
	            }else{//more errors
	            }
	            
				setIsLoading(false);
		
			}).catch(function (error) {
				console.log('deletePaymentFromDB error: ',error + ' back-end api call error');
	            
				setIsLoading(false);
			});

		}).catch(function (error) {
			console.log('deletePaymentFromDB error: ',error + ' csrf-cookie is outdated');
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
	                	
			setIsLoading(false);
			
			navHistory('/login');
		
		});
	}

  	return (
		<div className="body-content bg-fff pt-70l-110m-50s">
		
			<Snackbar open={openAlertDeleted} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} style={{ marginTop: "70px" }}>
				<Alert onClose={handleClose} severity="error">Payment deleted successfully!</Alert>
			</Snackbar>
			
			<div className="panel largeX ptb-20 plr-20l-10s">
			
				<div className="grid-x">
					<div className="large-11 medium-11 small-10 cell text-left">
						<div className="font-raleway page-header font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-5">Payments</div>
					</div>
					<div className="large-1 medium-1 small-2 cell text-right">
						{/*to="/member/payment_manager"*/}
						{/*onClick={() => handlePaymentAddClick()}*/}
						<Link to="/member/payment_manager" className="hover-opacity-50">
							<img src={payments_icon} className="br-5" width="40" alt="add payment"/>
						</Link>
					</div>
				</div>
				
				{paymentList.length > 0  ? (
					<>
					<div className="grid-x bg-ddd ptb-5 mt-5">
						<div className="large-4 medium-6 small-8 cell p-5 text-left hide-for-520px">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Payment</div>
						</div>
						<div className="large-4 medium-6 small-10 cell p-5 text-left show-for-520px">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Payment</div>
						</div>
						<div className="large-1 medium-0 small-0 cell p-5 text-center show-for-large-only">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Amount</div>
						</div>
						<div className="large-1 medium-0 small-0 cell p-5 text-center show-for-large-only">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Date</div>
						</div>
						<div className="large-1 medium-0 small-0 cell p-5 text-center show-for-large-only">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Gateway</div>
						</div>
						<div className="large-0 medium-3 small-2 cell p-5 text-center hide-for-large-only">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Details</div>
						</div>
						<div className="large-5 medium-3 small-2 cell p-5 text-center hide-for-520px">
							<div className="font-source-sans font-standard font-weight-600 txt-000">Transaction</div>
						</div>
					</div>
					<div id="payment_list" className="grid-x">
					{paymentList.map((payment, i) => (
						<>
						<div key={'no_edit_1a_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-4 medium-6 small-8 cell bg-fff p-5 text-left hide-for-520px' : 'large-4 medium-6 small-8 cell bg-eee p-5 text-left hide-for-520px'}>
							<div className="font-source-sans font-standard font-weight-600 txt-333" valign="top">{payment.title}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_payer_name}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_payer_email}</div>
						</div>
						<div key={'no_edit_1b_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-4 medium-6 small-10 cell bg-fff p-5 text-left show-for-520px' : 'large-4 medium-6 small-10 cell bg-eee p-5 text-left show-for-520px'}>
							<div className="font-source-sans font-standard font-weight-600 txt-333" valign="top">{payment.title}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_payer_name}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_payer_email}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_id} - {payment.trx_intent} {payment.trx_status}</div>
						</div>
						<div key={'no_edit_2_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-1 medium-0 small-0 cell bg-fff p-5 text-center show-for-large-only' : 'large-1 medium-0 small-0 cell bg-eee p-5 text-center show-for-large-only'}>
							<div className="font-source-sans font-standard font-weight-600 txt-333" valign="top">${payment.amount}</div>
						</div>
						<div key={'no_edit_3_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-1 medium-0 small-0 cell bg-fff p-5 text-center show-for-large-only' : 'large-1 medium-0 small-0 cell bg-eee p-5 text-center show-for-large-only'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">{convertDateTimeToText(payment.created_at)}</div>
						</div>
						<div key={'no_edit_4_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-1 medium-0 small-0 cell bg-fff p-5 text-center show-for-large-only' : 'large-1 medium-0 small-0 cell bg-eee p-5 text-center show-for-large-only'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">{payment.gateway}</div>
						</div>

						
						<div key={'no_edit_5_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-0 medium-3 small-2 cell bg-fff p-5 text-center hide-for-large-only' : 'large-0 medium-3 small-2 cell bg-eee p-5 text-center hide-for-large-only'}>
							<div className="font-source-sans font-standard font-weight-600 txt-333" valign="top">${payment.amount}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{convertDateTimeToText(payment.created_at)}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.gateway}</div>
						</div>


						<div key={'no_edit_6_'+payment.id+payment.random_id} className={i % 2 === 0 ? 'large-5 medium-3 small-2 cell bg-fff p-5 text-center hide-for-520px' : 'large-5 medium-3 small-2 cell bg-eee p-5 text-center hide-for-520px'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top"><span className="hide-for-small-only">{payment.trx_id}</span><span className="show-for-small-only">{shortenSomeString(payment.trx_id)}</span></div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_intent}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-3" valign="top">{payment.trx_status}</div>
						</div>
						</>
					))}
					</div>
					<div className="grid-x">
						<div id="payment_list_pagination" className="large-12 medium-12 small-12 cell pt-10">
							{/* Pagination links */}
							{lastPage > 1 && (
							<ul className="pagination">
								{prevPage && (
								<li className="page-item">
								<Link className="page-link" onClick={() => getPayments(currentPage - 1)}>Previous</Link>
								</li>
								)}
								
								{[...Array(lastPage).keys()].map(page => (
								<li key={page + 1} className={`page-item ${page + 1 === currentPage ? 'active' : ''}`}>
								<Link className="page-link" onClick={() => getPayments(page + 1)}>{page + 1}</Link>
								</li>
								))}
								
								{nextPage && (
								<li className="page-item">
								<Link className="page-link" onClick={() => getPayments(currentPage + 1)}>Next</Link>
								</li>
								)}
							</ul>
							)}
						</div>
					</div>
					</>
				) : (
					<div className="grid-x">
						<div className="large-12 medium-12 small-12 cell text-left">
							<div className="clearfix vertical-center-content pt-10 pr-5">
								<span className="font-raleway page-text font-weight-600 txt-dark-blue left">{paymentsListMessage}</span>
								<span className="font-raleway page-standard font-weight-600 txt-dark-blue right">Add Payment <img src={arrow_right_90} width="35" alt="note for order"/></span>
							</div>
							<div className="text-center p-20 b1-ccc bg-fafafa mt-20">
								<span className="font-raleway page-text font-weight-600 txt-333">Payment Transactions Provided by Paypal API (sandbox)</span>
							</div>
						</div>
					</div>
				)}
				
				{isLoading && 
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">
						<LoadingSpinner paddingClass="p-20l-10s" />
					</div>
				</div>
				}
				
			</div>
      
		</div>
	);
}

export default Payments;


function convertDateTimeToText(some_date_time){
	
	if(!some_date_time || some_date_time === ''){
		return '';
	}else{
		var date = new Date(some_date_time);
		
	    var day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
	    var month = (date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
		var year = String(date.getFullYear()).slice(-2);
	
		return month + '/' + day + '/' + year;
	}
	
}
function shortenSomeString(some_string){
	return some_string.slice(0, some_string.length / 2) + '...';
}