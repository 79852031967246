import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

import $ from "jquery";
import axios from 'axios';
import swal from 'sweetalert';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function PaymentManager(){

	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInstructionsOpen, setIsInstructionsOpen] = React.useState(true);
	const [paymentInput, setPaymentInput] = useState({
		title: '',
		amount: '',
		paypal_details: [],
		errorList: [],
		errorStyle: [],
	});
    const [showPaypalButtons, setShowPaypalButtons] = React.useState(false);
	
	const handleInput = (event) => {
		event.persist();
		
	    setPaymentInput({...paymentInput, [event.target.name]: event.target.value });

		if(paymentInput.amount === null || paymentInput.amount === '' || paymentInput.amount <= 0){
			setShowPaypalButtons(false);
		}
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (paymentInput.errorList.hasOwnProperty(event.target.name)){
			delete paymentInput.errorList[event.target.name];
			delete paymentInput.errorStyle[event.target.name];
		}
	}
	const handleToggleInstructions = (event) => {
		if(isInstructionsOpen){
			setIsInstructionsOpen(false);
		}else{
			setIsInstructionsOpen(true);
		}
	}
	
	const handleCancel = (event) => {
		event.persist();

		navHistory('/member/payments');
	}

	const amountSubmit = (event) => {
		event.persist();

		setShowPaypalButtons(true);

	}

	const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
			  {
				amount: {
				  value: paymentInput.amount,
				  currency_code: 'USD',
				},
				payee: {
				  email_address: 'sb-wduwx29178237@personal.example.com',
				},
				reference_id: 'sandbox account',
			  }
			],
			application_context: {
			  brand_name: 'Demo App',
			},
		});
	  };
	
	  const onApprove = (data, actions) => {
		return actions.order.capture().then((paypal_details) => {
		  // Handle successful split payment
		  console.log('payment successful:', paypal_details);
		  
		  setPaymentInput({...paymentInput, paypal_details: paypal_details});

		  setTimeout(() => {
			savePayment(paypal_details);
	    }, 250);
		
		});
	  };
	
	  function savePayment(paypal_details) {

		var payment_title = paymentInput.title;
		if(paymentInput.title === '' || paymentInput.title === null || paymentInput.title === undefined){
			payment_title = 'Paypal Test Payment';
		}
		
		setIsLoading(true);

		//values sent to api
		const payload = {
			title: payment_title,
			amount: paymentInput.amount,
			paypal_transaction_id: paypal_details.id,
			paypal_transaction_intent: paypal_details.intent,
			paypal_transaction_status: paypal_details.status,
			paypal_transaction_payer_email: paypal_details.payer.email_address,
			paypal_transaction_payer_name: paypal_details.payer.name.given_name +' '+ paypal_details.payer.name.surname,
		}
		
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.post('/api/save_payment', payload, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
				}
				}).then(response =>{
				if(response.data.status === 200){//HTTP_OK
					
					//update all state properties
	                	
					navHistory('/member/payments');
						
	            }else if(response.data.status === 401){//HTTP_UNAUTHORIZED
	            
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
	            
					swal("Warning",response.data.message,"warning");
	                	
					navHistory('/login');
					
	            }else if(response.data.status === 404){//HTTP_NOT_FOUND
	                swal("Warning",response.data.message,"warning");
	            }else if(response.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
	                swal("Warning",response.data.message,"warning");
				}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
					
					var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
					Object.keys(errorStyleTemp).map((key) => (
						errorStyleTemp[key] = error_style
					));
					
					//validation errors mapped to input fields
					setPaymentInput({...paymentInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
	
	            }else{//more errors
				}
				
				setIsLoading(false);
		
			}).catch(function (error) {
				console.log('paymentSubmit error: ',error + ' back-end api call error');
			
				setIsLoading(false);
			});

		}).catch(function (error) {
			console.log('paymentSubmit error: ',error + ' csrf-cookie is outdated');
		
			setIsLoading(false);
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
			
			navHistory('/login');
		});

	}
    
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel medium pt-20">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway page-header font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-5">Add Payment</div>
					</div>
		
					<div className="large-12 medium-12 small-12 cell bg-eee b1-ccc p-10 mt-20">
						<div className="clearfix">
							<div className="font-raleway font-medium font-weight-800 left">Instructions:</div>
							<div className="right">
								<Link onClick={handleToggleInstructions} className="button tiny uppercase right" valign="top">
								{isInstructionsOpen ? (<span>Close</span>) : (<span>Open</span>)}
								</Link>
							</div>
						</div>
						{isInstructionsOpen ? (
						<div id="instructions" className="">
							<div className="font-raleway font-standard font-weight-500 line-height-150per justify pt-10">
								The payment feature uses the Paypal Sandbox environment for testing purposes, so no real money is exchanging hands.
								Enter a payment title and amount then hit submit to trigger the Paypal Options. Select a payment option, either a Paypal Test Account (email & password) or a Test Credit Card.
							</div>
							<div className="font-raleway font-standard font-weight-500 line-height-150per pt-20">
							<span className="font-weight-800">Paypal Test Account Credentials</span>
								<br/>
								email:&nbsp;&nbsp;<b>sb-owtag29278995@personal.example.com</b>
								<br/>
								password:&nbsp;&nbsp;<b>pppw1234</b>
							</div>
							<div className="font-raleway font-standard font-weight-500 line-height-150per pt-20">
								<span className="font-weight-800">Test Credit Card</span>
								<br/>
								CC #:&nbsp;&nbsp;<b>4032033002522956</b>
								<br/>
								expires:&nbsp;&nbsp;<b>01/2029</b>
								<br/>
								Any csv, name, and address can be provided.
							</div>
						</div>
						):(
							<></>
						)}
					</div>
					<div className="large-12 medium-12 small-12 cell pt-10">
						<div className="grid-x">
							<div className="large-6 medium-6 small-12 cell text-left pt-10 pr-5l-0s">
								<label className="">Title <span className={paymentInput.errorStyle.title}>{paymentInput.errorList.title}</span>
									<input type="text" name="title" onChange={handleInput} value={paymentInput.title}  />
								</label>
							</div>
							<div className="large-6 medium-6 small-12 cell text-left pt-10 pl-5l-0s">
								<label className="">Amount ($) <span className={paymentInput.errorStyle.amount}>{paymentInput.errorList.amount}</span>
									<input type="number" step="any" name="amount" onChange={handleInput} value={paymentInput.amount}  />
								</label>
							</div>
							<div className="large-6 medium-6 small-6 cell text-left pt-20">
								<button onClick={handleCancel} className="button width-125px-100px uppercase">Cancel</button>
							</div>
							<div className="large-6 medium-6 small-6 cell text-right pt-20">
								<button type="submit" onClick={amountSubmit} className="button width-125px-100px uppercase">Submit</button>
							</div>
							<div className="large-12 medium-12 small-12 cell text-center">
								{isLoading && 
								<LoadingSpinner paddingClass="" />
								}
							</div>
						</div>
					</div>
					{showPaypalButtons === true ? (
					<div className="large-12 medium-12 small-12 cell text-center pt-20">
						<PayPalScriptProvider
							options={{
								'client-id': 'AYNH8RkDzKgeY7_U8CZCK7wbeicJZDxtjVZ4hr1LNu_5ziZykm9oeXE3PgCDJ_MKT9x0OnlFEVQ6IZbx',
							}}
						>
						<PayPalButtons
							style={{ layout: 'vertical' }}
							createOrder={createOrder}
							onApprove={onApprove}
						/>
						</PayPalScriptProvider>
					</div>
					) : (
						<></>
					)}
					
				</div>
			</div>
		</div>
	);
}

export default PaymentManager;