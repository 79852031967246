import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AuthUtility from '../../components/frontend/auth/AuthUtility';

import OffCanvasNav from './OffCanvasNav';

import axios from 'axios';
import swal from 'sweetalert';

import logo from '../../assets/frontend/images/demo_logo.png';

const OnCanvasHeader = () => {
	
	//logout should be its own component
	const navHistory = useNavigate();

	const logoutSubmit = (event) => {
		event.preventDefault();
		
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.get('/api/logout', {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				}
			}).then(response =>{
				if(response.data.status === 200){//success

					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
					
					//redirect to home page
					navHistory('/');
				}else if(response.data.status === 401){//user was not logged in

					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
					
					swal("Warning",response.data.message,"warning");
						
					navHistory('/login');
				}else{
					console.log('Error 40X: api call failed');
					
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
					
					navHistory('/login');
				}
		
			}).catch(function (error) {
				console.log('logoutSubmit error: ',error + ' back-end api call error');
			});

		}).catch(function (error) {
			console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
			
			navHistory('/login');
		
		});
	}

	var RoleDashboardLink = '';
	var RoleProfileLink = '';
	var RoleSettingsLink = '';
	var DashboardLinks = '';
	var Navigation = '';

    if(localStorage.getItem('auth_users_name') && localStorage.getItem('auth_email')
	&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
		
		RoleProfileLink = '/' + localStorage.getItem('auth_role') + '/profile';
		if(localStorage.getItem('auth_role') === 'member'){
			RoleDashboardLink = '/member/dashboard';
			RoleProfileLink = '/member/profile';
			RoleSettingsLink = '/member/settings';
			
			DashboardLinks = (
				<ul>
					<li className="menu2"><Link to="/member/check_list" className="font-raleway font-small font-weight-500 uppercase">Check List</Link></li>
					<li className="menu2"><Link to="/member/portfolio" className="font-raleway font-small font-weight-500 uppercase">Portfolio</Link></li>
					<li className="menu2"><Link to="/member/traffic" className="font-raleway font-small font-weight-500 uppercase">Traffic</Link></li>
					<li className="menu2"><Link to="/member/weather" className="font-raleway font-small font-weight-500 uppercase">Weather</Link></li>
					<li className="menu2"><Link to="/member/videos" className="font-raleway font-small font-weight-500 uppercase">Videos</Link></li>
					<li className="menu2"><Link to="/member/payments" className="font-raleway font-small font-weight-500 uppercase">Payments</Link></li>
				</ul>
			);
		}else if(localStorage.getItem('auth_role') === 'admin'){
			RoleDashboardLink = '/member/dashboard';
			RoleProfileLink = '/admin/profile';
			RoleSettingsLink = '/admin/settings';
			
			DashboardLinks = (
				<ul>
					<li className="menu2"><Link to="/admin/users" className="font-raleway font-small font-weight-500 uppercase">Users</Link></li>
					<li className="menu2"><Link to="/admin/check_list" className="font-raleway font-small font-weight-500 uppercase">Check List</Link></li>
					<li className="menu2"><Link to="/admin/portfolio" className="font-raleway font-small font-weight-500 uppercase">Portfolio</Link></li>
					<li className="menu2"><Link to="/admin/traffic" className="font-raleway font-small font-weight-500 uppercase">Traffic</Link></li>
					<li className="menu2"><Link to="/admin/weather" className="font-raleway font-small font-weight-500 uppercase">Weather</Link></li>
					<li className="menu2"><Link to="/admin/videos" className="font-raleway font-small font-weight-500 uppercase">Videos</Link></li>
					<li className="menu2"><Link to="/admin/payments" className="font-raleway font-small font-weight-500 uppercase">Payments</Link></li>
				</ul>
			);
		}
	
		Navigation = (
			<nav className="nav right">
				<ul>
					<li className="dropdown text-center">
						<Link to="/home" className="menu1 font-raleway font-small font-weight-500 uppercase">Home</Link>
						<ul>
							<li className="menu1"><Link to="/search_videos" className="menu1 font-raleway font-small font-weight-500 uppercase">Search Videos</Link></li>
							<li className="menu1"><Link to="/about" className="menu1 font-raleway font-small font-weight-500 uppercase">About</Link></li>
							<li className="menu1"><Link to="/contact" className="menu1 font-raleway font-small font-weight-500 uppercase">Contact</Link></li>
							<li className="menu1"><Link to="/help" className="menu1 font-raleway font-small font-weight-500 uppercase">Help</Link></li>
							<li className="menu1"><Link to="/technical_highlights" className="home font-raleway font-small font-weight-500 uppercase">Technical</Link></li>
							<li className="menu1"><Link to="/instructions" className="home font-raleway font-small font-weight-500 uppercase">Instructions</Link></li>
						</ul>
					</li>
					<li className="dropdown text-center">
						<Link to={RoleDashboardLink} className="menu2 font-raleway font-small font-weight-500 uppercase">Dashboard</Link>
						{DashboardLinks}
					</li>
					<li className="dropdown text-center">
						<Link to={RoleProfileLink} className="menu3 font-raleway font-small font-weight-500 uppercase">{localStorage.getItem('auth_users_name')}</Link>
						<ul>
							<li className="menu3"><Link to={RoleProfileLink} className="menu3 font-raleway font-small font-weight-500 uppercase">Profile</Link></li>
							<li className="menu3"><Link to={RoleSettingsLink} className="menu3 font-raleway font-small font-weight-500 uppercase">My Settings</Link></li>
							<li className="menu3"><Link to="#" className="menu3 font-raleway font-small font-weight-500 uppercase" onClick={logoutSubmit}>Logout</Link></li>
						</ul>
					</li>
				</ul>
			</nav>
		);
	}else{
	
		Navigation = (
			<nav className="nav right">
				<ul>
					<li className="menu1"><Link to="/login" className="font-raleway font-small font-weight-500 uppercase">Login</Link></li>
					<li className="menu1"><Link to="/register" className="font-raleway font-small font-weight-500 uppercase">Register</Link></li>
					<li className="dropdown text-center">
						<Link to="/home" className="menu1 font-raleway font-small font-weight-500 uppercase">Home</Link>
						<ul>
							<li className="menu1"><Link to="/search_videos" className="menu1 font-raleway font-small font-weight-500 uppercase">Search Videos</Link></li>
							<li className="menu1"><Link to="/about" className="menu1 font-raleway font-small font-weight-500 uppercase">About</Link></li>
							<li className="menu1"><Link to="/contact" className="menu1 font-raleway font-small font-weight-500 uppercase">Contact</Link></li>
							<li className="menu1"><Link to="/help" className="menu1 font-raleway font-small font-weight-500 uppercase">Help</Link></li>
							<li className="menu1"><Link to="/technical_highlights" className="home font-raleway font-small font-weight-500 uppercase">Technical</Link></li>
							<li className="menu1"><Link to="/instructions" className="home font-raleway font-small font-weight-500 uppercase">Instructions</Link></li>
						</ul>
					</li>
				</ul>
			</nav>
		);
	}

	return(
		<div className="sticky-by-cr">
			<div className="sticky bg-fafafa bb1-ddd plr-20l-10s">
				
				<div className="panel">
				
					<div id="nav_bar" className="nav-bar">
						<div className="nav-bar-left">
							<div className="clearfix">
								<Link to="/" className="hover-opacity-50 hide-for-small-only">
									<span className="left">
										<img src={logo} alt="logo" width="50"/>
									</span>
									<span className="text-left pl-10 left">
										<div className="font-raleway font-x-large font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-5">DEMO APP</div>
										<div className="font-raleway font-standard font-weight-600 txt-dark-red bt2-dark-red letter-spacing-0px uppercase pt-7 mt-5">Fusing All-in-1</div>
									</span>
								</Link>
								<Link to="/" className="hover-opacity-50 show-for-small-only">
									<span className="left">
										<img src={logo} alt="logo" width="40"/>
									</span>
									<span className="text-left pl-7 left">
										<div className="font-raleway font-medium font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-3">DEMO APP</div>
										<div className="font-raleway font-small font-weight-600 txt-dark-red bt2-dark-red letter-spacing-0px uppercase pt-5 mt-3">Fusing All-in-1</div>
									</span>
								</Link>
							</div>
						</div>
						<div className="nav-bar-right  hide-for-small-only">
							{Navigation}
						</div>
						<div className="nav-bar-right show-for-small-only">
							<OffCanvasNav />
						</div>
					</div>
				</div>
			
			</div>
		</div>
	);
}

export default OnCanvasHeader;